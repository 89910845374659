"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyCalculator = exports.RentCalculator = exports.BuyParameters = exports.RentParameters = exports.GlobalParameters = exports.Breakdown = exports.LinearlyIncreasingValue = void 0;
function computeIncreaseFactor(percentage) {
    return 1 + percentage / 100;
}
function convertAnnualIncreaseFactorToDaily(annualIncreaseFactor) {
    return Math.pow(annualIncreaseFactor, 1 / 365);
}
var Constant = /** @class */ (function () {
    function Constant(value) {
        this.value = value;
    }
    Constant.prototype.getValue = function () {
        return this.value;
    };
    Constant.prototype.setValue = function (value) {
        this.value = value;
    };
    return Constant;
}());
var LinearIncreasingFactor = /** @class */ (function () {
    function LinearIncreasingFactor(value) {
        this.setValue(value);
    }
    LinearIncreasingFactor.prototype.getValue = function () {
        return this.value;
    };
    LinearIncreasingFactor.prototype.setValue = function (value) {
        this.value = value;
        this.factor = computeIncreaseFactor(value);
    };
    LinearIncreasingFactor.prototype.getFactor = function () {
        return this.factor;
    };
    return LinearIncreasingFactor;
}());
var LinearlyIncreasingValue = /** @class */ (function () {
    function LinearlyIncreasingValue(initialValue, expectedAnnualIncrease) {
        this.initialValue = initialValue;
        this.expectedAnnualIncrease = expectedAnnualIncrease;
    }
    LinearlyIncreasingValue.prototype.annualIncreaseFactor = function () {
        return this.expectedAnnualIncrease.getFactor();
    };
    return LinearlyIncreasingValue;
}());
exports.LinearlyIncreasingValue = LinearlyIncreasingValue;
var Breakdown = /** @class */ (function () {
    function Breakdown(mapping) {
        this.mapping = mapping;
    }
    Breakdown.prototype.total = function () {
        return Object.values(this.mapping).reduce(function (a, b) { return a + b; }, 0);
    };
    return Breakdown;
}());
exports.Breakdown = Breakdown;
var NO_COST = new Breakdown({});
var GlobalParameters = /** @class */ (function () {
    function GlobalParameters() {
        this.expectedAnnualRoROnAnotherInvestment = new LinearIncreasingFactor(8);
        this.timeHorizon = new Constant(40);
    }
    GlobalParameters.prototype.expectedDailyRateOfReturn = function () {
        return convertAnnualIncreaseFactorToDaily(this.expectedAnnualRoROnAnotherInvestment.getFactor());
    };
    return GlobalParameters;
}());
exports.GlobalParameters = GlobalParameters;
var RentParameters = /** @class */ (function () {
    function RentParameters() {
        this.rent = new LinearlyIncreasingValue(new Constant(1700), new LinearIncreasingFactor(2));
        this.rentDeposit = new Constant(0);
    }
    return RentParameters;
}());
exports.RentParameters = RentParameters;
var BuyParameters = /** @class */ (function () {
    function BuyParameters() {
        this.homeValue = new LinearlyIncreasingValue(new Constant(250000), new LinearIncreasingFactor(6));
        this.mortgage = new Constant(200000);
        this.mortgageInterestRate = new Constant(8);
        this.mortgageLength = new Constant(30);
        this.hoa = new LinearlyIncreasingValue(new Constant(700), new LinearIncreasingFactor(1));
        this.propertyTax = new LinearlyIncreasingValue(new Constant(200), new LinearIncreasingFactor(1));
        this.homeInsurance = new LinearlyIncreasingValue(new Constant(200), new LinearIncreasingFactor(1));
        this.realtorFee = new Constant(2);
        this.mortgageClosingCosts = new Constant(3000);
        this.otherFixedBuySideCosts = new Constant(0);
        this.otherFixedSellSideCosts = new Constant(0);
    }
    return BuyParameters;
}());
exports.BuyParameters = BuyParameters;
var SimpleInvestmentAccount = /** @class */ (function () {
    function SimpleInvestmentAccount(globalParameters) {
        this.dailyRateOfReturn = globalParameters.expectedDailyRateOfReturn();
        this.value = 0;
    }
    SimpleInvestmentAccount.prototype.initialCosts = function () {
        return 0;
    };
    SimpleInvestmentAccount.prototype.totalValue = function () {
        return new Breakdown({ "Investment Account": this.value });
    };
    SimpleInvestmentAccount.prototype.computeMonthlyPayment = function () {
        return NO_COST;
    };
    SimpleInvestmentAccount.prototype.allocateToExternalInvestments = function (amount) {
        if (isNaN(amount)) {
            throw new Error("allocating NaN");
        }
        this.value += amount;
    };
    SimpleInvestmentAccount.prototype.onNewYear = function () {
    };
    SimpleInvestmentAccount.prototype.onNewDay = function () {
        this.value *= this.dailyRateOfReturn;
    };
    return SimpleInvestmentAccount;
}());
var RentCalculator = /** @class */ (function () {
    function RentCalculator(globalParameters, rentParameters) {
        this.investmentAccount = new SimpleInvestmentAccount(globalParameters);
        this.monthlyRentPayment = rentParameters.rent.initialValue.getValue();
        this.rentDeposit = rentParameters.rentDeposit.getValue();
        this.rentAnnualIncreaseFactor = rentParameters.rent.annualIncreaseFactor();
    }
    RentCalculator.prototype.initialCosts = function () {
        return this.investmentAccount.initialCosts() + this.rentDeposit;
    };
    RentCalculator.prototype.totalValue = function () {
        return this.investmentAccount.totalValue();
    };
    RentCalculator.prototype.computeMonthlyPayment = function () {
        return new Breakdown({ "Rent": this.monthlyRentPayment });
    };
    RentCalculator.prototype.allocateToExternalInvestments = function (amount) {
        this.investmentAccount.allocateToExternalInvestments(amount);
    };
    RentCalculator.prototype.onNewYear = function () {
        this.investmentAccount.onNewYear();
        this.monthlyRentPayment *= this.rentAnnualIncreaseFactor;
    };
    RentCalculator.prototype.onNewDay = function () {
        this.investmentAccount.onNewDay();
    };
    return RentCalculator;
}());
exports.RentCalculator = RentCalculator;
function calculateMonthlyAmortizedMortgagePayment(P, r, n) {
    var k = Math.pow(1 + r, n);
    return P * (r * k) / (k - 1);
}
var BuyCalculator = /** @class */ (function () {
    function BuyCalculator(globalParameters, buyParameters) {
        this.params = buyParameters;
        this.amountInHomeEquityIfBuying = buyParameters.homeValue.initialValue.getValue() - buyParameters.mortgage.getValue();
        this.investmentAccount = new SimpleInvestmentAccount(globalParameters);
        this.purchasePrice = buyParameters.homeValue.initialValue.getValue();
        this.appreciatedHomeValue = this.purchasePrice;
        this.actualHOAMonthly = buyParameters.hoa.initialValue.getValue();
        this.actualPropertyTaxMonthly = buyParameters.propertyTax.initialValue.getValue();
        this.actualHomeInsuranceMonthly = buyParameters.homeInsurance.initialValue.getValue();
        this.dailyPropertyAppreciationFactor = convertAnnualIncreaseFactorToDaily(buyParameters.homeValue.annualIncreaseFactor());
        this.annualHOAIncreaseFactor = buyParameters.hoa.annualIncreaseFactor();
        this.annualPropertyTaxIncreaseFactor = buyParameters.propertyTax.annualIncreaseFactor();
        this.annualHomeInsuranceIncreaseFactor = buyParameters.homeInsurance.annualIncreaseFactor();
        this.realtorFee = buyParameters.realtorFee.getValue() / 100;
        this.mortgagePrincipal = buyParameters.mortgage.getValue();
        this.monthlyInterestRate = (buyParameters.mortgageInterestRate.getValue() / 100) / 12;
        this.totalMonthlyPayments = buyParameters.mortgageLength.getValue() * 12;
        this.monthlyMortgagePayment = calculateMonthlyAmortizedMortgagePayment(this.mortgagePrincipal, this.monthlyInterestRate, this.totalMonthlyPayments);
        this.totalMonthlyPaymentsMade = 0;
    }
    BuyCalculator.prototype.initialCosts = function () {
        return this.amountInHomeEquityIfBuying + this.params.mortgageClosingCosts.getValue() + this.params.otherFixedBuySideCosts.getValue();
    };
    BuyCalculator.prototype.totalValue = function () {
        var investmentAccountValue = this.investmentAccount.totalValue();
        var appreciatedEquity = this.appreciatedHomeValue * (this.amountInHomeEquityIfBuying / this.purchasePrice);
        investmentAccountValue.mapping["Appreciated Home Equity"] = appreciatedEquity;
        investmentAccountValue.mapping["Expected Selling Costs"] = -(this.realtorFee * appreciatedEquity + this.params.otherFixedSellSideCosts.getValue());
        return investmentAccountValue;
    };
    BuyCalculator.prototype.computeMonthlyPayment = function () {
        var costBreakdown = {};
        if (this.totalMonthlyPaymentsMade < this.totalMonthlyPayments) {
            this.totalMonthlyPaymentsMade++;
            var interestForMonth = this.monthlyInterestRate * this.mortgagePrincipal;
            var principalRepaymentForMonth = this.monthlyMortgagePayment - interestForMonth;
            this.mortgagePrincipal -= principalRepaymentForMonth;
            this.amountInHomeEquityIfBuying += principalRepaymentForMonth;
            costBreakdown["Mortgage Principal Repayment"] = principalRepaymentForMonth;
            costBreakdown["Mortgage Interest Repayment"] = interestForMonth;
            if (Math.abs((principalRepaymentForMonth + interestForMonth) - this.monthlyMortgagePayment) > 0.001) {
                throw new Error("Invalid payment detected!");
            }
        }
        else if (Math.abs(this.amountInHomeEquityIfBuying - this.purchasePrice) > 0.01) {
            throw new Error("Done with payments but equity of " + this.amountInHomeEquityIfBuying + " != purchase price of " + this.purchasePrice);
        }
        costBreakdown["Homeowner Costs"] = this.actualHOAMonthly + this.actualHomeInsuranceMonthly + this.actualPropertyTaxMonthly;
        return new Breakdown(costBreakdown);
    };
    BuyCalculator.prototype.allocateToExternalInvestments = function (amount) {
        this.investmentAccount.allocateToExternalInvestments(amount);
    };
    BuyCalculator.prototype.onNewDay = function () {
        this.investmentAccount.onNewDay();
        this.appreciatedHomeValue *= this.dailyPropertyAppreciationFactor;
    };
    BuyCalculator.prototype.onNewYear = function () {
        this.investmentAccount.onNewYear();
        this.actualHOAMonthly *= this.annualHOAIncreaseFactor;
        this.actualPropertyTaxMonthly *= this.annualPropertyTaxIncreaseFactor;
        this.actualHomeInsuranceMonthly *= this.annualHomeInsuranceIncreaseFactor;
    };
    return BuyCalculator;
}());
exports.BuyCalculator = BuyCalculator;
